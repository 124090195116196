import React from 'react';

import { graphql, navigate } from 'gatsby';

import BlogCard from '../components/BlogCard';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

import { Box, Container, Pagination, Typography } from '@mui/material';

function Blog(props) {
    const {
        edges: posts,
        pageInfo: { currentPage, pageCount },
    } = props.data.allMarkdownRemark;

    const handlePageChange = (_, pageNumber) => {
        if (pageNumber === currentPage) return;
        if (pageNumber === 1) navigate('/blog');
        else navigate(`/blog/${pageNumber}`);
    };

    return (
        <Layout>
            <Box sx={{ p: 4 }}>
                <Typography variant="h2" align="center" color="primary">
                    Dezkr Blog
                </Typography>
                <Typography variant="subtitle1" align="center" gutterBottom>
                    Product Management Concepts, Tips and Scenarios by the Dezkr
                    Team
                </Typography>
            </Box>
            <Container maxWidth="md" sx={{ my: 4 }}>
                {posts.map(({ node }) => (
                    <Box key={node.id} sx={{ my: 2 }}>
                        <BlogCard post={node} />
                    </Box>
                ))}

                <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                        count={pageCount}
                        shape="rounded"
                        color="secondary"
                        siblingCount={2}
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </Box>
            </Container>

            <Footer />
        </Layout>
    );
}

export default Blog;

export const Head = () => {
    return <Seo title="Dezkr Blog" />;
};

export const pageQuery = graphql`
    query blogQuery($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            sort: { fields: frontmatter___date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 250)
                    timeToRead
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        image_alt_text
                        description
                        tags
                        featured_image {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: CONSTRAINED
                                    height: 200
                                )
                            }
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                pageCount
            }
        }
    }
`;
